import styled, { css } from 'styled-components'

export const Container = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 56px 14.5px;
  max-width: 100%;
  width: 100%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 50px 20px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1440px;
    padding: 100px 108px 50px 108px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const HeaderLeft = styled.div``

export const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.text3}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.text4}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text1}
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
  }
`

export const HeaderRight = styled.div`
  padding-bottom: 4px;
  width: 100%;
  margin-top: 14px;
  overflow-x: auto;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  gap: 8px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 0;
    max-width: 365.9px;
    overflow-x: initial;
    flex-wrap: wrap;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 642px;
    gap: 10px;
  }
`

export const CategoryBtn = styled.a<{ clicked: boolean }>`
  flex: 1 0 auto;
  ${({ theme }) => theme.mixins.text2}
  text-decoration: none;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid #fff;
  border-radius: 20px;
  height: fit-content;
  width: fit-content;

  &:hover {
    border: 1px solid #000000;
  }
  ${({ clicked }) => {
    if (clicked) {
      return css`
        border: 1px solid #000000;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 5.95px 8.92px;
    flex: none;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text1}
    padding: 10px 15px;
  }
`

export const CourseContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 18px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 14px;
    margin-top: 25px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 55px;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 19px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 32px;
  }
`

export const Button = styled.a`
  text-decoration: none;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 50px;
  height: 45px;
  transition: 0.3s;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 10px;
    line-height: 12px;
    max-width: 193px;
    height: 26px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text2}
    max-width: 290px;
    height: 45px;
  }
`
