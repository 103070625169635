import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport'
import { useTranslation } from 'next-i18next'
/**
 * styles
 */
import * as Styled from './styled'
/**
 * api
 */
import { useProductQuery } from 'src/api/product'
import { useCategoryQuery } from 'src/api/category'
/**
 * ui
 */
import ModuleCard from 'src/components/ModuleCard'
/**
 * hook
 */
import useWindowSize from 'src/hooks/useWindowSize'

export const Categories: React.FC = () => {
  const { t } = useTranslation('common')
  const { width } = useWindowSize()
  const { query } = useRouter()
  const [categorySlug, setCategorySlug] = React.useState<string>('')
  const [currentCategory, setCurrentCategory] =
    React.useState<string>('catalogue')
  // data fetching
  const { data: categoriesData } = useCategoryQuery.useGetCategories()
  const { data: productsData } = useProductQuery.useGetProducts({
    categorySlug,
    limit: width < 743.98 ? '3' : '6',
  })
  React.useEffect(() => {
    if (query.category) {
      setCategorySlug(query.category as string)
    }
  }, [query])

  if (!categoriesData || !productsData) {
    return null
  }
  return (
    <Styled.Container id='catalogue'>
      <Styled.Header>
        <Styled.HeaderLeft>
          <Styled.Subtitle>Выбирай кем хочешь стать</Styled.Subtitle>
          <Styled.Title>Направления обучения</Styled.Title>
        </Styled.HeaderLeft>
        <Styled.HeaderRight>
          <Link href='/' passHref scroll={false} shallow replace legacyBehavior>
            <Styled.CategoryBtn
              onClick={() => setCategorySlug(() => '')}
              clicked={'' === categorySlug}
            >
              Все
            </Styled.CategoryBtn>
          </Link>
          {categoriesData.map(slug => (
            <Link
              passHref
              href={`/?category=${slug.slug}`}
              key={slug.slug}
              scroll={false}
            >
              <Styled.CategoryBtn
                clicked={slug.slug === categorySlug}
                onClick={() => setCurrentCategory(slug.slug)}
              >
                {slug.title}
              </Styled.CategoryBtn>
            </Link>
          ))}
        </Styled.HeaderRight>
      </Styled.Header>

      <Styled.CourseContainer>
        {productsData.items.map(item => {
          const card = new ModuleCard(item)
          return card.render(item.slug)
        })}
      </Styled.CourseContainer>

      {productsData?.total > productsData.items.length && (
        <Styled.ButtonWrapper>
          <Link href={currentCategory} passHref>
            <Styled.Button>
              еще{' '}
              {t('course', {
                count: productsData.total - productsData.items.length,
              })}{' '}
              из {productsData.total}
            </Styled.Button>
          </Link>
        </Styled.ButtonWrapper>
      )}
    </Styled.Container>
  )
}
const CategoriesViewport = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { forwardedRef, enterCount } = props
  return <div ref={forwardedRef}>{enterCount > 0 ? <Categories /> : null}</div>
}

export default handleViewport(CategoriesViewport)
